<template>
  <Page :title="title" :error="error">
    <ListingFilters :parentId="+$route.query.parentId" />
    <ListingsTable :items-per-page="100" :filter="filter" sync />
  </Page>
</template>

<script>
import ListingsTable from '../components/ListingsTable.vue'
import ListingFilters from '../components/ListingFilters.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ListingsTable,
    ListingFilters
  },
  computed: {
    ...mapGetters('ListingHierarchies', [
      'getById',
      'getDescendantsByParentId',
      'getAncestors'
    ]),
    ...mapGetters('Listings', ['getListingFilterQuery', 'error']),
    parentId() {
      return +this.$route.query.parentId
    },
    title() {
      if (!this.parentId) {
        return 'Listings'
      }

      const root = this.getById(this.parentId)
      const ancestors = this.getAncestors(root)
      const names = ancestors.map((x) => x.name)
      names.unshift(root.name)
      names.reverse()

      return `Listings: ${names.join(' / ')}`
    },
    filter() {
      const filters = []
      const ids = this.getDescendantsByParentId(this.parentId)
        .map((x) => x.id)
        .join(',')

      if (ids) {
        filters.push(`parentId in (${ids})`)
      }

      const listingFilterQuery = this.getListingFilterQuery(
        this.$route.query.filter
      )

      if (listingFilterQuery) {
        filters.push(listingFilterQuery)
      }

      return filters.join(' and ')
    }
  }
}
</script>
