<template>
  <div>
    <span
      v-for="(listingFilter, i) in listingFilters"
      :key="listingFilter.name"
    >
      <router-link
        :class="{ active: isActive(listingFilter) }"
        :to="getRoute(listingFilter)"
        @click.native="onClick"
        >{{ listingFilter.name }} ({{ listingFilter.count }})</router-link
      >
      <span class="mx-2" v-if="i < listingFilters.length - 1">|</span>
    </span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('Listings')

export default {
  props: {
    parentId: Number
  },
  watch: {
    parentId: {
      handler(val) {
        this.getFilterCounts(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['listingFilters'])
  },
  methods: {
    ...mapActions(['getFilterCounts']),
    getRoute(filter) {
      const query = { ...this.$route.query }
      query.filter = filter.name

      delete query.page

      return { name: 'Listings', query }
    },
    isActive(filter) {
      return filter.name === this.$route.query.filter
    },
    onClick() {
      this.getFilterCounts(this.parentId)
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
a.active {
  font-weight: bold;
}
</style>
